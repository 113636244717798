import clsx from "clsx";
import { ReactNode } from "react";

interface IStickProps {
  children: ReactNode;
}

interface IProps {
  countA: number;
  countB: number;
  countC: number;
}

const StickLabel = (props: IStickProps) => {
  return (
    <div className="relative w-[2px] h-full bg-gray-500">
      <p className="absolute top-full pt-3 text-center transform -translate-x-1/2">
        {props.children}
      </p>
    </div>
  );
};

const CountProgrees = (props: IProps) => {
  const max = Math.max(props.countC, props.countB);
  const min = Math.min(props.countC, props.countB);

  const progressPercent = ((min - props.countA) * 100) / (max - props.countA);

  return (
    <div
      className={clsx(
        "flex justify-between relative h-12",
        props.countC < props.countB ? "bg-slate-400" : "bg-MainAccent"
      )}
    >
      <StickLabel>{props.countA}</StickLabel>
      <div
        className="bg-MainAccent flex justify-end h-full absolute left-0"
        style={{ width: `${progressPercent}%` }}
      >
        <StickLabel>{min}</StickLabel>
      </div>
      <StickLabel>{max}</StickLabel>
    </div>
  );
};

export default CountProgrees;
