import { useRef, useState } from "react";
import Button from "../../components/Button";
import ModalWrapper from "../../components/ModalWrapper";
import down from "../../assets/img/promoDown.svg";
import warning from "../../assets/img/warning.svg";
import trash from "../../assets/img/trash.svg";
import file from "../../assets/img/file.svg";
import attach from "../../assets/img/attach.svg";
import { baseURL } from "../../config";

type steps = {
  step: "submit" | "over";
};
type input = {
  lastname?: string;
  name?: string;
  patronomic?: string;
  mail?: string;
  phone?: string;
  select?: string;
  organization?: string;
  project_name?: string;
  orderer?: string;
  files?: any;
};

const ProjectRegistration = ({}) => {
  const [step, setStep] = useState<steps>({ step: "submit" });
  const [uniq, setUniq] = useState<string>("0");
  const [input, setInput] = useState<input>({});
  const [mailError, setMailError] = useState<boolean>(false);
  const [filesUpload, setFilesUpload] = useState<Array<File>>([]);
  const [rules, setRules] = useState<boolean>(false);
  const [eula, setEula] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);

  const selector = document.getElementById("selector");

  const files = useRef<any>(null);

  const formData = new FormData();

  const handleSubmit = () => {
    var index = 0;
    var newFiles: any = [];
    for (const f of filesUpload) {
      formData.append(index.toString(), f);
      newFiles.push({
        name: f.name,
        id: index.toString(),
      });
      index++;
    }
    formData.append("json", JSON.stringify({ ...input, files: newFiles }));
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("POST", `${baseURL}/set/promo/create_project`);
    xhr.onload = () => {
      if (xhr.status == 200) {
        setUniq(JSON.parse(xhr.responseText).ok);
        handleModal();
      }
    };
    xhr.send(formData);
    setInput({});
  };

  const handleModal = () => {
    setIsModal(!isModal);
  };

  return (
    <div>
      {step.step === "submit" && (
        <div className={`!w-full`}>
          <div
            className={`bg-MainAccent rounded-bl-[127px] !w-full pl-32 pt-12 pb-16 overflow-auto`}
          >
            <p
              className={`text-white font-semibold text-[36px] leading-[48px]`}
            >
              Зарегистрировать проект для участия
            </p>
            <p
              className={`text-white font-semibold text-[36px] leading-[48px]`}
            >
              в акции Создавай SYSTEME
            </p>
          </div>
          <div className={`flex gap-5 ml-24 mt-4`}>
            <p className={`leading-6 text-[22px] text-MainAccent`}>Шаг</p>
            <span
              className={`circle relative bottom-[54px] !bg-MainAccent border border-white`}
            >
              1
            </span>
            <p className={`leading-6 text-[22px] text-MainAccent`}>
              Заполните данные участника
            </p>
          </div>
          <div className={`flex flex-col items-start ml-24 w-[840px] gap-3`}>
            <div className="relative w-full ">
              <input
                type="text"
                id="lastname"
                className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
                placeholder=" "
                required={true}
                onChange={(event) => {
                  setInput({ ...input, lastname: event.currentTarget.value });
                }}
              />

              <label
                htmlFor="lastname"
                className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                Фамилия *
              </label>
            </div>
            <div className="relative w-full ">
              <input
                type="text"
                id="name"
                className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
                placeholder=" "
                required={true}
                onChange={(event) => {
                  setInput({ ...input, name: event.currentTarget.value });
                }}
              />

              <label
                htmlFor="name"
                className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                Имя *
              </label>
            </div>
            <div className="relative w-full ">
              <input
                type="text"
                id="patronomic"
                className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
                placeholder=" "
                onChange={(event) => {
                  setInput({ ...input, patronomic: event.currentTarget.value });
                }}
              />

              <label
                htmlFor="patronomic"
                className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                Отчество
              </label>
            </div>
            <div className="relative w-full ">
              <input
                type="text"
                id="mail"
                className={`block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none focus:outline-none focus:ring-0 focus:border-MainAccent peer ${
                  mailError ? "border-CloseDeadlineAccent" : ""
                } first_page`}
                placeholder=" "
                onChange={(event) => {
                  if (
                    event.currentTarget.value.match(
                      "(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)"
                    ) ||
                    event.currentTarget.value.length === 0
                  ) {
                    setMailError(false);
                    setInput({ ...input, mail: event.currentTarget.value });
                  } else {
                    setMailError(true);
                  }
                }}
                required={true}
              />
              {mailError && (
                <p className="text-[12px] leading-4 text-CloseDeadlineAccent mt-0.5 base">
                  Пожалуйста, проверьте введенный адрес
                </p>
              )}

              <label
                htmlFor="mail"
                className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                E-mail *
              </label>
            </div>
            <div className="relative w-full ">
              <input
                type="text"
                id="phone"
                className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
                placeholder=" "
                required={true}
                onChange={(event) => {
                  setInput({ ...input, phone: event.currentTarget.value });
                }}
              />

              <label
                htmlFor="phone"
                className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                Контактный номер телефона *
              </label>
            </div>
            <div
              className="flex items-center relative px-4 rounded-lg h-12 w-full group focus:border-MainAccent border border-footer text-[14px] leading-4 text-SecondaryAccent-dark "
              tabIndex={0}
              id="selector"
            >
              <span id="select">
                {!input.select ? "Регион участника*" : input.select}
              </span>
              <img
                src={down}
                className="absolute right-4 transition-all  group-focus:-scale-y-100  group-focus:transform"
                alt=""
              ></img>
              <div className="hidden z-20 group-focus:flex w-full mt-1 flex-col absolute bg-white top-full left-0 p-6 shadow-plate">
                <p
                  className="text-sm font-normal text-unselected hover:text-selected cursor-pointer py-2 option"
                  onClick={(event) => {
                    if (selector) {
                      selector.blur();
                    }
                    setInput({
                      ...input,
                      select: event.currentTarget.textContent || "",
                    });
                  }}
                >
                  Центр
                </p>
                <p
                  className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
                  onClick={(event) => {
                    if (selector) {
                      selector.blur();
                    }
                    setInput({
                      ...input,
                      select: event.currentTarget.textContent || "",
                    });
                  }}
                >
                  Северо-Западный Федеральный округ
                </p>
                <p
                  className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
                  onClick={(event) => {
                    if (selector) {
                      selector.blur();
                    }
                    setInput({
                      ...input,
                      select: event.currentTarget.textContent || "",
                    });
                  }}
                >
                  Приволжский Федеральный округ
                </p>
                <p
                  className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
                  onClick={(event) => {
                    if (selector) {
                      selector.blur();
                    }
                    setInput({
                      ...input,
                      select: event.currentTarget.textContent || "",
                    });
                  }}
                >
                  Южный Федеральный округ
                </p>
                <p
                  className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
                  onClick={(event) => {
                    if (selector) {
                      selector.blur();
                    }
                    setInput({
                      ...input,
                      select: event.currentTarget.textContent || "",
                    });
                  }}
                >
                  Урал
                </p>
                <p
                  className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
                  onClick={(event) => {
                    if (selector) {
                      selector.blur();
                    }
                    setInput({
                      ...input,
                      select: event.currentTarget.textContent || "",
                    });
                  }}
                >
                  Сибирь
                </p>
                <p
                  className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
                  onClick={(event) => {
                    if (selector) {
                      selector.blur();
                    }
                    setInput({
                      ...input,
                      select: event.currentTarget.textContent || "",
                    });
                  }}
                >
                  Дальний Восток
                </p>
              </div>
            </div>
            <div>
              <Button
                text={`Продолжить`}
                onClick={() => {
                  setStep({ step: "over" });
                }}
                className={`mt-8 h-14 w-40 rounded-full`}
                disabled={
                  !(
                    input.select &&
                    input.lastname &&
                    input.name &&
                    input.mail &&
                    input.phone
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
      {step.step === "over" && (
        <div>
          <div
            className={`bg-MainAccent w-full rounded-bl-[127px] pl-32 pt-12 pb-16`}
          >
            <p
              className={`font-semibold text-[36px] leading-[48px] text-white`}
            >
              Зарегистрировать проект для участия
            </p>
            <p
              className={`font-semibold text-[36px] leading-[48px] text-white`}
            >
              {" "}
              в акции Создавай SYSTEME
            </p>
          </div>
          <div className={`flex gap-5 ml-24 mt-4`}>
            <p className={`text-[22px] leading-6 text-MainAccent`}>Шаг</p>
            <span
              className={`circle relative bottom-[54px] !bg-MainAccent border border-white`}
            >
              2
            </span>
            <p className={`text-[22px] leading-6 text-MainAccent`}>
              Заполните данные о проекте
            </p>
          </div>
          <div className={`flex flex-col items-start ml-24 w-[840px] gap-3`}>
            <div
              className={`bg-SecondaryAccent-veryLight rounded-2xl w-full relative mb-5`}
            >
              <img
                src={warning}
                alt="warning"
                className={`absolute bottom-full ml-5 translate-y-1/2`}
              />
              <p className={`text-center py-4 text-[14px] leading-4 base`}>
                Если хотите зарегистрировать несколько проектов, их нужно
                оформлять отдельными заявками
              </p>
            </div>
            <div className="relative w-full ">
              <input
                type="text"
                id="organization"
                className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer second_page"
                placeholder=" "
                onChange={(event) => {
                  setInput({
                    ...input,
                    organization: event.currentTarget.value,
                  });
                }}
              />

              <label
                htmlFor="organization"
                className="absolute text-xs text-SecondaryAccent  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-MainAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                Наименование проектной организации *
              </label>
            </div>
            <div className="relative w-full ">
              <input
                type="text"
                id="project_name"
                className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer second_page"
                placeholder=" "
                onChange={(event) => {
                  setInput({
                    ...input,
                    project_name: event.currentTarget.value,
                  });
                }}
              />

              <label
                htmlFor="project_name"
                className="absolute text-xs text-SecondaryAccent  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-MainAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                Наименование проекта *
              </label>
            </div>
            <div className="relative w-full ">
              <input
                type="text"
                id="orderer"
                className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer second_page"
                placeholder=" "
                onChange={(event) => {
                  setInput({ ...input, orderer: event.currentTarget.value });
                }}
              />

              <label
                htmlFor="orderer"
                className="absolute text-xs text-SecondaryAccent  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-MainAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
              >
                Конечный заказчик *
              </label>
            </div>
            <div className="mt-8">
              <p className="base text-[22px] leading-6 mb-4">
                Загрузите документацию по проекту *
              </p>
              <p className="base text-[16px] leading-6 text-SecondaryAccent-dark">
                Принимается любая документация по проекту, содержащая данные о
                примененном в проекте оборудовании: схемы, спецификации,
                пояснительные записки, опросные листы. Приложенная документация
                должна иметь штамп с указанной датой выпуска в течение 2023
                года. Участнику необходимо приложить два или более файла в
                формате: .pdf, .doc, .docx, .xls, .xlsx, .dwg, .rvt, .7z, .rar,
                .zip, .zipx
              </p>
            </div>
            <div className="w-full mt-16">
              <p className="base text-[22px] leading-6 mb-4">
                Загруженные документы
              </p>
              {filesUpload && (
                <div className="w-full" id="filesContainer">
                  {Array.from(filesUpload).map((el) => {
                    return (
                      <div className={`flex justify-between py-4 border-b `}>
                        <div className={`flex items-center`}>
                          <img src={file} alt="file" className={`mr-5`} />
                          <p
                            className={`font-semibold base text-sm leading-4 text-SecondaryAccent`}
                          >
                            <span className={`text-SecondaryAccent-dark`}>
                              {el.name}/
                            </span>
                            {el.size} КБ
                          </p>
                        </div>
                        <img
                          src={trash}
                          alt="trash"
                          className={`cursor-pointer`}
                          onClick={() => {
                            const files = filesUpload;
                            const newFiles = files?.filter(function (f: any) {
                              return f !== el;
                            });
                            setFilesUpload(newFiles);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div
              className="flex w-full gap-3 cursor-pointer mt-2"
              id="fileBtn"
              onClick={(event) => {
                files.current.click();
              }}
            >
              <img src={attach} alt="attach"></img>
              <p className="text-sm font-semibold text-MainAccent base">
                Прикрепить файлы
              </p>
              <input
                onChange={(event) => {
                  if (event.currentTarget.files) {
                    setInput({ ...input, files: event.currentTarget.files });
                    setFilesUpload([
                      ...(filesUpload || []),
                      ...Array.from(event.currentTarget.files),
                    ]);
                  }
                }}
                type="file"
                ref={files}
                id="fileInput"
                name="project3"
                multiple
                accept=".pdf, .doc, .docx, .xls, .xlsx, .dwg, .rvt, .7z, .rar, .zip, .zipx"
                className="hidden"
              ></input>
            </div>
            <div className="flex gap-2 mt-16">
              <input
                type="checkbox"
                className="check"
                onChange={() => {
                  setRules(!rules);
                }}
              ></input>
              <p className="base text-[12px] leading-4 text-SecondaryAccent-dark">
                Подтверждаю, что ознакомлен и согласен с{" "}
                <a
                  href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                  className="text-systeme underline"
                >
                  Правилами акции
                </a>
              </p>
            </div>
            <div className="flex gap-2 items-start">
              <input
                type="checkbox"
                className="check"
                onChange={() => {
                  setEula(!eula);
                }}
              ></input>
              <p className="base text-[12px] leading-4 text-SecondaryAccent-dark">
                Заполняя настоящую регистрационную форму, я даю согласие
                акционерному обществу «Систэм Электрик» на обработку
                содержащихся в ней персональных данных в соответствии с
                условиями и порядком обработки моих персональных данных,
                установленных{" "}
                <a
                  href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                  className="text-systeme underline"
                >
                  Политикой акционерного общества «Систэм Электрик»
                </a>{" "}
                в отношении обработки персональных данных и сведениями о
                реализуемых требованиях к защите персональных данных, в целях
                получения информации о продуктах и доступных решениях Систэм
                Электрик, акцептом которых является заполнение данной формы
              </p>
            </div>
            <Button
              text={`Отправить`}
              className={`mt-8 h-14 w-40 rounded-full`}
              onClick={handleSubmit}
              disabled={
                !(
                  input.project_name &&
                  input.orderer &&
                  input.organization &&
                  rules &&
                  eula &&
                  input.files?.length != 0
                )
              }
            />
          </div>
          <ModalWrapper
            header={"Заявка отправлена"}
            text={``}
            onClose={handleModal}
            visible={isModal}
          >
            <p className={`base`}>
              Уникальный номер участника, который будет использоваться для
              отображения вашего результата в турнирной таблице:{" "}
              <strong>{uniq}</strong>
            </p>
          </ModalWrapper>
        </div>
      )}
    </div>
  );
};

export default ProjectRegistration;
