import { useCallback, useState } from "react";
import {
  PromoUserResp,
  PromoUser,
  PromoType,
} from "../../network/PromoApi/promoInterfaces";
import Button from "../Button";
import ModalWrapper from "../ModalWrapper";
import MainInput from "../welcome/MainInput";
import {
  checkKeys,
  isValidEmail,
  isValidINN,
  isValidNumber,
} from "../../utils/data";
import SpecializationSelect from "./SpecializationSelect";
import { useAuth } from "../../Providers/PromoUserProvider";

interface IProps {
  user: PromoUser;
  visible?: boolean;
  onClose?: () => void;
  onSave: (user: PromoUser) => void;
}

const UserEditModal = (props: IProps) => {
  const [userInfo, setUserInfo] = useAuth();

  const [newUserData, setNewUserData] = useState<PromoUser>(props.user);

  const INNError =
    newUserData?.INN !== undefined && !isValidINN(newUserData.INN);
  const phoneError =
    newUserData?.phone !== undefined && !isValidNumber(newUserData?.phone);

  const emailError =
    newUserData?.login !== undefined && !isValidEmail(newUserData?.login);

  const setKey = useCallback(
    (key: keyof PromoUser, data: string) => {
      setNewUserData({
        ...((newUserData as PromoUser) || {}),
        [key]: data,
      });
    },
    [newUserData]
  );

  const checkKeysData = checkKeys<PromoUser>;

  return (
    <ModalWrapper
      mainClass={`rounded-[32px] min-w-[500px]`}
      withoutBanner={true}
      header={``}
      text={``}
      onClose={props.onClose}
      visible={props.visible}
      crossClass={`p-[3%] `}
    >
      <div className={`flex flex-col w-full]`}>
        <div>
          <MainInput
            error={emailError}
            onChange={(e) => {
              setKey("login", e);
            }}
            id={"mail"}
            name={"E-mail"}
            value={newUserData.login}
          />
        </div>
        <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
          <MainInput
            onChange={(e) => {
              setKey("name", e);
            }}
            id={"name"}
            name={"Имя"}
            value={newUserData.name}
          />
          <MainInput
            onChange={(e) => {
              setKey("lastname", e);
            }}
            id={"lastname"}
            name={"Фамилия"}
            value={newUserData.lastname}
          />
        </div>
        <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
          <MainInput
            onChange={(e) => {
              setKey("INN", e);
            }}
            error={INNError}
            id={"inn"}
            name={"ИНН"}
            value={newUserData.INN}
          />
          <MainInput
            onChange={(e) => {
              setKey("phone", e);
            }}
            error={phoneError}
            id={"phone"}
            className="pl-10"
            prefix="+7 | "
            name={"Номер телефона"}
            value={newUserData.phone}
          />
        </div>
        <div className={`flex flex-col gap-4 mt-4`}>
          <MainInput
            onChange={(e) => {
              setKey("company", e);
            }}
            id={"company"}
            name={"Компания"}
            value={newUserData.company}
          />
          <SpecializationSelect
            selectedValue={props.user.specializtion}
            type={userInfo ? userInfo.type : PromoType.ONE}
            onInput={(value) => {
              setKey("specializtion", value);
            }}
          />
          <MainInput
            onChange={(e) => {
              setKey("state", e);
            }}
            id={"state"}
            name={"Должность"}
            value={newUserData.state}
          />
        </div>

        <Button
          text={"Сохранить"}
          className={`mt-10 py-5 rounded-[32px] font-semibold text-[18px] base`}
          disabled={
            emailError ||
            !checkKeysData(newUserData, [
              "login",
              "company",
              "state",
              "lastname",
              "name",
              "INN",
            ]) ||
            INNError ||
            phoneError
          }
          onClick={() => {
            props.onSave(newUserData);
          }}
        />
      </div>
    </ModalWrapper>
  );
};

export default UserEditModal;
