import React from "react";
import windows from "../../assets/img/welcome/windows.png";
import man from "../../assets/img/welcome/man.png";
import woman from "../../assets/img/welcome/woman.png";
import cup from "../../assets/img/welcome/cup.png";

const MainFormats = () => (
    <div
        className={`z-[0] flex flex-col rounded-tl-[48px] rounded-tr-[48px] px-[5%] backdrop-blur sm:h-fit sm:overflow-visible sm:pb-10`}
        style={{
            backgroundImage: `url(${windows})`,
            backgroundPositionX: "25%",
            backgroundPositionY: "23%",
            backgroundRepeat: "repeat",
            backgroundSize: "cover",
        }}
    >
        <div
            id={"overlay_format"}
            className={`absolute top-0 left-0 h-full w-full z-[-1] rounded-tl-[48px] rounded-tr-[48px] opacity-80 bg-gradient-to-br from-[#787F86] from-10% via-[#18434B] to-[#787F86]`}
        ></div>
        <div
            id={`content`}
            className={`mt-[20vh] lg:mt-[5vh] flex flex-col tall:mt-10 relative`}
        >
            <div className={`text-white text-[72px] sm:text-[36px]`}>
                <p>Удобные форматы</p>
            </div>
            <div className={``}>
                <div
                    className={`flex justify-start gap-16 lg:gap-6 flex-row lg:flex-col mt-32 lg:mt-10 base tall:mt-10 tall:flex-col tall:gap-6`}
                >
                    <div
                        className={`max-w-[560px] min-w-[560px] max-h-[976px] min-h-[976px] lg:min-w-full lg:min-h-[320px] bg-[#F6F6F61A] backdrop-blur-[10px] rounded-[32px] flex flex-col lg:flex-row justify-between sm:flex-col tall:flex-row tall:min-w-full tall:min-h-[30px]`}
                    >
                        <div className={`px-8`}>
                            <p className={`font-medium text-white text-[32px] mt-[5vh] `}>
                                Экспертные сессии
                            </p>
                            <p className={`text-white text-[18px] leading-[30px] mt-[3vh]`}>
                                Подключайтесь к онлайн-семинарам, узнавайте о новом оборудовании
                                и решениях Systeme Electric и задавайте ваши вопросы эксперту.
                                Пропустили сессию? Ничего страшного! Вы сможете посмотреть её в
                                записи.
                            </p>
                        </div>
                        <div className={`lg:min-w-[380px]  sm:mt-10`}>
                            <img
                                className={`object-cover tall:object-contain`}
                                src={man}
                                alt=""
                            />
                        </div>
                    </div>
                    <div
                        className={`flex flex-col justify-between text-white sm:justify-between sm:gap-4`}
                    >
                        <div
                            className={` bg-[#F6F6F61A] backdrop-blur-[10px] rounded-[32px] lg:min-w-full lg:max-h-[320px] mb-[2%] flex justify-between sm:flex-col sm:max-h-full tall:flex-row tall:min-w-full tall:max-h-[280px]`}
                        >
                            <img
                                className={`object-cover lg:min-w-[380px] tall:min-w-0 tall:object-contain`}
                                src={woman}
                                alt=""
                            />
                            <div className={`flex flex-col px-[3vw] sm:pb-4 gap-16 md:gap-8`}>
                                <p className={`font-medium text-[32px] mt-[5vh] md:leading-8`}>
                                    Профессиональные курсы
                                </p>
                                <p>
                                    Основной формат платформы — профессиональные курсы. Вы сможете
                                    успешно развиваться в программе, уделяя обучению всего 15
                                    минут в день. Выстраивайте индивидуальный график и получайте
                                    именной сертификат по завершении курса.
                                </p>
                            </div>
                        </div>
                        <div
                            className={` bg-[#F6F6F61A] backdrop-blur-[10px] rounded-[32px] lg:min-w-full mb-[10%] lg:max-h-[320px] flex justify-between sm:flex-col sm:max-h-full tall:min-w-full tall:max-h-[220px]`}
                        >
                            <div className={`flex flex-col px-[3vw] gap-16 md:gap-8`}>
                                <p className={`font-medium text-[32px] mt-[5vh] md:leading-8`}>
                                    Видеоподкасты «Кофе с экспертом»
                                </p>
                                <p>
                                    «Кофе с экспертом» — это 10-минутные видео,в которых эксперты
                                    делятся своим опытом и знаниями в области проектирования и не
                                    только.
                                </p>
                            </div>

                            <img
                                className={`object-cover lg:min-w-[380px] sm:mt-5 tall:min-w-0 tall:object-cover tall:max-w-[250px]`}
                                src={cup}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default MainFormats;
