import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Providers/PromoUserProvider";
import Button from "../../components/Button";
import CountProgrees from "../../components/Create/CountProgrees";
import Loader from "../../components/Loader";
import { PromoSpecialization } from "../../network/PromoApi/promoInterfaces";
import { logout } from "../../network/PromoApi/promoApi";
import AppRoutes from "../../structure/routes";

interface IProps {}

const LINE_BY_SPEC = {
  [PromoSpecialization.DILLER]: "ЧТо то",
  [PromoSpecialization.OEM]: "ЧТо то",
  [PromoSpecialization.SHIELD]: "ЧТо то",
  [PromoSpecialization.CMO]: "ЧТо то",
};

const PromoCabinet = (props: IProps) => {
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useAuth();
  return userInfo !== undefined ? (
    <div className="px-10">
      <div
        className={`min-h-[92px] flex flex-row items-center base justify-between`}
      >
        <p>
          {userInfo.name} {userInfo.lastname} | {userInfo.company}
        </p>
        <div>
          <Button
            text={"Выход"}
            className={
              "bg-SecondaryAccent-veryLight text-MainAccent mr-4 h-[60px] w-[124px] rounded-full hover:bg-SecondaryAccent-light font-medium sm:hidden"
            }
            onClick={() => {
              logout()
              setUserInfo(undefined)
              navigate(AppRoutes.Create)
            }}
          />
        </div>
      </div>

      <p>Код участника: {userInfo.id}</p>
      <p>Email: {userInfo.login}</p>
      <p>Phone: +7 | {userInfo.phone}</p>
      <p>Компания: {userInfo.company}</p>
      <p>ИНН: {userInfo.INN}</p>
      <p className="mb-5">Должность: {userInfo.state}</p>

      {userInfo.statistic !== undefined && (
        <div>
          <p className="mb-3">
            Для того, чтобы стать претендентом на поездку в Китай, необходимо
            достичь минимальной цели закупки на {userInfo.statistic.countB} млн.
            руб по оборудованию {LINE_BY_SPEC[userInfo.specializtion]}
          </p>

          <CountProgrees
            countA={userInfo.statistic.countA}
            countB={userInfo.statistic.countB}
            countC={userInfo.statistic.countC}
          />
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default PromoCabinet;
