import Button from "../../components/Button";
import down from "../../assets/img/promoDown.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import ModalWrapper from "../../components/ModalWrapper";
import InputForm from "../../components/InputForm";
import { shopOrderReq } from "../../network/PromoApi/promoInterfaces";
import { orderPromoShop } from "../../network/PromoApi/promoApi";
import { request } from "http";

interface Prize {
  im: number | string;
  name: string;
  point: number;
}

const REQUEST_INFO_DEFAULT = {
  name: "",
  userId: "",
  phone: "",
  delivery: "",
  product: "",
  price: 0,
};

const PrizesForSystemepoints = ({}) => {
  const prizesArr: Prize[] = [
    {
      im: 41,
      name: "Электрический самокат Tribe Himba",
      point: 719,
    },
    {
      im: 28,
      name: 'Монитор игровой HUAWEI MateView GT 27" VA черный XWU-CBA',
      point: 921,
    },
    {
      im: 26,
      name: "Кофемашина автоматическая DeLonghi Magnifica EVO",
      point: 1750,
    },
    {
      im: 6,
      name: "Смарт-часы Samsung Galaxy Watch6 44mm, графит",
      point: 1050,
    },
    {
      im: 16,
      name: "Умная колонка Яндекс Станция Макс с Zigbee черная",
      point: 1105,
    },
    {
      im: 1,
      name: "Фитнес-браслет Xiaomi Mi Band 8 Золотистый",
      point: 148,
    },
    {
      im: 2,
      name: "Фитнес-браслет Xiaomi Mi Band 8 Черный",
      point: 148,
    },
    {
      im: 3,
      name: "Смарт-часы Samsung Galaxy Watch4 40mm, цвет серебро",
      point: 590,
    },
    {
      im: 4,
      name: "Смарт-часы Samsung Galaxy Watch4 44mm, черный",
      point: 608,
    },
    {
      im: 5,
      name: "Смарт-часы Samsung Galaxy Watch6 40mm, цвет белый/золото",
      point: 866,
    },
    {
      im: 11,
      name: "Портативная колонка JBL Pulse 5 Black",
      point: 940,
    },
    {
      im: 10,
      name: "Портативная колонка JBL Flip 6 Black",
      point: 461,
    },
    {
      im: 13,
      name: "Умная колонка Яндекс Станция Лайт с Алисой",
      point: 163,
    },
    {
      im: 14,
      name: "Умная колонка Яндекс Станция Мини с часами с Алисой",
      point: 295,
    },
    {
      im: 15,
      name: "Умная колонка Яндекс Станция 2 с Алисой",
      point: 663,
    },
    {
      im: 23,
      name: "Кофемашина капсульного типа Krups Dolce Gusto Piccolo XS KP1A01(10/31)",
      point: 627,
    },
    {
      im: 24,
      name: "Кофемашина капсульного типа DeLonghi Nespresso ENV120.GY",
      point: 314,
    },
    {
      im: 25,
      name: "Кофемашина Philips EP1220/00 Series 1200",
      point: 1069,
    },
    {
      im: 27,
      name: 'Монитор игровой Thunderobot 23.8" IPS черный F23H75',
      point: 442,
    },
    {
      im: 12,
      name: "Портативная колонка HARMAN-KARDON HK Onyx Studio 8 Blue",
      point: 958,
    },
    {
      im: 17,
      name: "Беспроводные наушники с микрофоном JBL Tune 510BT черный",
      point: 185,
    },
    {
      im: 18,
      name: "Беспроводные наушники с микрофоном JBL Tune 710BT белый",
      point: 314,
    },
    {
      im: 19,
      name: "Наушники накладные Bluetooth Bose QuietComfort QC45 серый",
      point: 1069,
    },
    {
      im: 20,
      name: "Наушники True Wireless Samsung Galaxy Buds2 графит",
      point: 295,
    },
    {
      im: 9,
      name: "Портативная колонка JBL Go 3 Black",
      point: 177,
    },
    {
      im: "Отвертка",
      name: "Отвертка и набор бит Xiaomi Mi Precision Screwdriver Kit",
      point: 85,
    },
    {
      im: 33,
      name: "Набор отверток c 3-х компонентной ручкой 10 предметов Дело Техники 728100",
      point: 63,
    },
    {
      im: 35,
      name: "Набор шлицевых и крестовых отверток Jonnesway FULL STAR 8 пр. D04PP08S 47072",
      point: 163,
    },
    {
      im: 34,
      name: "Набор отверток и насадок KRAFTOOL X-Drive-50 50 шт. 25815",
      point: 174,
    },
    {
      im: 36,
      name: "Универсальный набор Makita D-42042, 103 шт, в кейсе",
      point: 240,
    },
    {
      im: 21,
      name: "Налобный фонарь NITECORE HC35 2700 люмен 1200 часов",
      point: 406,
    },
    {
      im: 38,
      name: "Ударная дрель Makita HP1640",
      point: 409,
    },
    {
      im: 37,
      name: "Аккумуляторная дрель-шуруповерт Makita DF347DWEX8",
      point: 450,
    },
    {
      im: 39,
      name: "Пылесос вертикальный Xiaomi Vacuum Cleaner G9 Plus EU Белый",
      point: 737,
    },
    {
      im: 40,
      name: "Робот-пылесос Roborock S7 белый",
      point: 1058,
    },
    {
      im: 29,
      name: "Игровая мышь Razer DeathAdder Essential",
      point: 74,
    },
    {
      im: 31,
      name: "Игровая мышь Razer Basilisk V3",
      point: 199,
    },
    {
      im: 30,
      name: "Игровая мышь Razer DeathAdder V2 X Hyperspeed",
      point: 203,
    },
    {
      im: 32,
      name: "Игровая мышь Razer Pro Click",
      point: 398,
    },
    {
      im: 22,
      name: "Массажный пистолет Xiaomi Massage Gun",
      point: 428,
    },
    {
      im: "Зарядное устройство",
      name: 'Беспроводное зарядное устройство "ShineMax SOFTTOUCH", 10000 mAh с цифровым индикатором заряда, черный',
      point: 135,
    },
    {
      im: 7,
      name: "Внешний жесткий диск Toshiba Canvio Basics 1TB",
      point: 277,
    },
    {
      im: 8,
      name: "Внешний жесткий диск Toshiba Canvio Basics 2TB",
      point: 332,
    },
    {
      im: "Кружка",
      name: "Кружка-хамелеон «Северное сияние»",
      point: 13,
    },
    {
      im: "Термос",
      name: "Термос «Confident» с покрытием soft-touch 420 мл, черный",
      point: 36,
    },
    {
      im: 44,
      name: "Электрочайник Moulinex Glass Inox BY600130",
      point: 111,
    },
    {
      im: 42,
      name: "Термопот Brayer 5 л 1093BR-WH",
      point: 343,
    },
    {
      im: 45,
      name: "Мультиварка Redmond RMC-03",
      point: 247,
    },
    {
      im: 46,
      name: "Электрогриль Redmond SteakMaster RGM-M817D",
      point: 369,
    },
    {
      im: 47,
      name: "Электрогриль DeLonghi CGH1112DP",
      point: 1032,
    },
    {
      im: 49,
      name: "Конструктор LEGO CREATOR 3-in-1 Винтажный мотоцикл, 128 деталей, 8+",
      point: 52,
    },
    {
      im: 50,
      name: "Конструктор Холодное Сердце - Ледяной замок Эльзы, 297 деталей, 6+",
      point: 67,
    },
    {
      im: 52,
      name: "Конструктор LEGO Friends Аквапарк, 373 детали, 6+",
      point: 115,
    },
    {
      im: 51,
      name: 'Конструктор Сити "Океан: Корабль исследователей морских глубин", 717 деталей',
      point: 174,
    },
    {
      im: 48,
      name: "Скоростной джип на радиоуправлении",
      point: 181,
    },
  ];

  const [prizes, setPrizes] = useState(prizesArr);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [prizesItems, setPrizesItems] = useState<any>();
  const [selectedPrize, setSelectedPrize] = useState<Prize | undefined>();
  const [requestInfo, setRequestInfo] =
    useState<shopOrderReq>(REQUEST_INFO_DEFAULT);

  const [visibleResponseModal, setVisibleResponseModal] =
    useState<boolean>(false);

  const selector = document.getElementById("selector");

  const setValueByKey = (key: string, value: string) => {
    setRequestInfo((r) => {
      (r as any)[key] = value;
      return { ...r };
    });
  };

  const isOneKeyNull = (object: any) => {
    for (let key in object) {
      if (!object[key]) return true;
    }
    return false;
  };

  const setPrize = (prize: Prize) => {
    setSelectedPrize(prize);
    setRequestInfo({ ...requestInfo, product: prize.name, price: prize.point });
  };

  useEffect(() => {
    if (selectedFilter === "По убыванию цены") {
      setPrizes(prizes.sort((a, b) => b.point - a.point));
    }
    if (selectedFilter === "По возрастанию цены") {
      setPrizes(prizes.sort((a, b) => a.point - b.point));
    }
    if (selectedFilter === "По умолчанию") {
      setPrizes(prizesArr);
    }
  }, [selectedFilter]);

  useEffect(() => {
    const prizesList = prizes.map((pr, i) => {
      const maxLenght = 95;
      let slisedName = undefined;
      if (pr.name.length > maxLenght) {
        slisedName = pr.name.slice(0, maxLenght) + "...";
      }
      return (
        <div key={i} className="flex flex-col w-48 h-[375px] mx-8 mb-7">
          <div className="flex-1 flex flex-col items-center">
            <div className="w-full flex justify-center h-48 mb-[16px]">
              <img
                className="max-h-48"
                src={`https://3lapi.devtoprod.ru/event_prises/${pr.im}.jpg`}
                alt=""
              />
            </div>
            <p className="text-center">{slisedName || pr.name}</p>
          </div>

        </div>
      );
    });
    setPrizesItems(prizesList);
  }, [prizes, selectedFilter]);

  return (
    <div className="flex flex-col items-center sm:mt-4 sm:px-4 mt-16">
      <div className={"w-[100%] mb-16"}>
        <p className={`base font-bold text-[26px] leading-[35px]`}>
          Призы в обмен на баллы SystemePoints{" "}
        </p>
        <p className={"my-5"}>
          Обменивайте полученные за участие в акции баллы SystemePoints на
          ценные призы! <br />
          Каждый участник сможет получить один или несколько призов в рамках
          накопленных баллов за весь период проведения акции. <br />
          Обмен баллов на призы будет доступен после подведения итогов акции – 16 февраля 2025 г.
        </p>
        <div className="flex flex-wrap ">
          <Link
            to={AppRoutes.PromoRegistration}
            className="underline hover:no-underline"
          >
            Регистрируйте больше проектов,
          </Link>
          <p> чтобы получить желаемый приз</p>
        </div>
        <p className="mb-83">Cписок призов будет уточнен</p>
        <div
          className="text- flex items-center relative px-4 rounded-3xl h-12 w-52 group border-MainAccent border border-footer text-[14px] leading-4 text-SecondaryAccent-dark cursor-pointer"
          tabIndex={0}
          id="selector"
        >
          <span id="selectedPrise">
            {!selectedFilter ? "Сортировать" : selectedFilter}
          </span>
          <img
            src={down}
            className="absolute right-4 transition-all  group-focus:-scale-y-100  group-focus:transform"
            alt=""
          ></img>
          <div className="hidden z-20 group-focus:flex w-full mt-1 flex-col absolute bg-white top-full left-0 p-6 shadow-plate">
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={() => {
                if (selector) {
                  selector.blur();
                }
                setSelectedFilter("По умолчанию");
              }}
            >
              По умолчанию
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={() => {
                if (selector) {
                  selector.blur();
                }
                setSelectedFilter("По возрастанию цены");
              }}
            >
              По возрастанию цены
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={() => {
                if (selector) {
                  selector.blur();
                }
                setSelectedFilter("По убыванию цены");
              }}
            >
              По убыванию цены
            </p>
          </div>
        </div>
      </div>
      <div className={"w-full flex flex-wrap sm:justify-center sm:mb-5 mb-14"}>{prizesItems}</div>
      <div className="flex h-14 w-96 sm:w-full mb-14">
        <Link className="flex flex-1" to={AppRoutes.PromoRegistration}>
          <Button
            className="flex-1 rounded-[30px]"
            text="Зарегистрировать проект"
          ></Button>
        </Link>
      </div>
      <ModalWrapper
        header={"Заполните заявку"}
        text={``}
        mainClass="min-w-[40%]"
        onClose={() => {
          setSelectedPrize(undefined);
        }}
        visible={selectedPrize !== undefined}
      >
        <InputForm
          placeholder="Имя и фамилия"
          required
          onChange={(v) => {
            setValueByKey("name", v);
          }}
        />
        <InputForm
          placeholder="Ваш ID участника акции"
          required
          onChange={(v) => {
            setValueByKey("userId", v);
          }}
        />
        <InputForm
          placeholder="Мобильный телефон для связи"
          required
          onChange={(v) => {
            setValueByKey("phone", v);
          }}
        />
        <InputForm
          placeholder="Адрес доставки (укажите почтовый индекс, город, улицу, номер дома, квартиру или офис)"
          required
          onChange={(v) => {
            setValueByKey("delivery", v);
          }}
        />
        <div className="w-full flex items-center">
          <Button
            text={"Отправить"}
            className="mx-auto"
            disabled={isOneKeyNull(requestInfo)}
            onClick={() => {
              orderPromoShop(requestInfo).then((r) => {
                if (r.status === "ok") {
                  setSelectedPrize(undefined);
                  setRequestInfo({...REQUEST_INFO_DEFAULT})
                  setVisibleResponseModal(true);
                }
              });
            }}
          />
        </div>
      </ModalWrapper>
      <ModalWrapper
        header={"Ваша заявка оформлена"}
        text={
          "Мы свяжемся с Вами по электронной почте и сообщим детали для вручения подарка"
        }
        visible={visibleResponseModal}
        onClose={() => {
          setVisibleResponseModal(false);
        }}
      ></ModalWrapper>
    </div>
  );
};

export default PrizesForSystemepoints;
