import { useState } from "react";
import {
  PromoSpecialization,
  PromoType,
} from "../../network/PromoApi/promoInterfaces";
import MainSelector, { SelectorItemType } from "../welcome/MainSelector";

interface IProps {
  type: PromoType;
  onInput: (value: string) => void;
  selectedValue?: string;
}

const SPECIALIZATIONS: { [key: string]: SelectorItemType[] } = {
  [PromoType.ONE]: [
    {
      name: "Шитовик",
      value: PromoSpecialization.SHIELD,
    },
    {
      name: "OEM",
      value: PromoSpecialization.OEM,
    },
    {
      name: "CMO",
      value: PromoSpecialization.CMO,
    },
    {
      name: "Дилер",
      value: PromoSpecialization.DILLER,
    },
  ],
  [PromoType.TWO]: [
    {
      name: "Шитовик",
      value: PromoSpecialization.SHIELD,
    },
    {
      name: "OEM",
      value: PromoSpecialization.OEM,
    },
    {
      name: "CMO",
      value: PromoSpecialization.CMO,
    },
    {
      name: "Дилер",
      value: PromoSpecialization.DILLER,
    },
  ],
  [PromoType.THREE]: [
    {
      name: "Шитовик",
      value: PromoSpecialization.SHIELD,
    },
    {
      name: "OEM",
      value: PromoSpecialization.OEM,
    },
    {
      name: "CMO",
      value: PromoSpecialization.CMO,
    },
    {
      name: "Дилер",
      value: PromoSpecialization.DILLER,
    },
  ],
};

const SpecializationSelect = (props: IProps) => {
  const [selectedSpecialization, setSelectedSpecialization] =
    useState<PromoSpecialization>(props.selectedValue as PromoSpecialization);

  return (
    <MainSelector
      select={selectedSpecialization}
      setInput={(e, c) => {
        setSelectedSpecialization(c as PromoSpecialization);
        props.onInput(c);
      }}
      items={SPECIALIZATIONS[props.type]}
      label="Специализация"
    />
  );
};

export default SpecializationSelect;
