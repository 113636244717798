import React, { useEffect, useRef, useState } from "react";
import { IParallax, Parallax, ParallaxLayer } from "@react-spring/parallax";

import WelcomeHeader from "../../components/welcome/WelcomeHeader";
import MainScreen from "../../components/welcome/MainScreen";
import MainAbout from "../../components/welcome/MainAbout";
import MainFormats from "../../components/welcome/MainFormats";
import MainStages from "../../components/welcome/MainStages";
import MainLearn from "../../components/welcome/MainLearn";
import MainRegister from "../../components/welcome/MainRegister";
import MainStudy from "../../components/welcome/MainStudy";
import MainFeatures from "../../components/welcome/MainFeatures";
import StageCard from "../../components/welcome/StageCard";
import finger from "../../assets/img/welcome/fingerButton.svg";
import cabinet from "../../assets/img/welcome/cabinetStage.png";
import cert from "../../assets/img/welcome/certStage.png";
import course from "../../assets/img/welcome/courseStage.svg";
import MainPrizes from "../../components/welcome/MainPrizes";
import ModalWrapper from "../../components/ModalWrapper";
import MainInput from "../../components/welcome/MainInput";
import MainSelector from "../../components/welcome/MainSelector";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { UserRegister } from "../../network/UserApi/userInterfaces";
import {
  login,
  passwordRecovery,
  userRegister,
} from "../../network/UserApi/userApi";
import AppRoutes from "../../structure/routes";
import arrow from "../../assets/img/welcome/arrow.svg";
import MainCompactNav from "../../components/welcome/MainCompactNav";
import { useResize } from "../../utils/useResize";
import ModalCross from "../../components/ModalCross";
import { Countries } from "../../structure/countries";

type IProps = {
  onAuth: (r: boolean) => void;
};

type stagesLogin = "login" | "register" | "recover" | "waited";

const stages = [
  {
    number: "01",
    header: "Регистрация",
    text: "Регистрируйтесь на платформе и получайте доступ к обучающим программам от Systeme Electric.",
  },
  {
    number: "02",
    header: "Каталог курсов",
    text: "Все курсы и видеолекции представлены в трех форматах и удобно скомпонованы по тематике. Большинство курсов и лекций можно посмотреть бесплатно.",
  },
  {
    number: "03",
    header: "Учебный кабинет",
    text: "Следите за прогрессом: на какие курсы вы записались, какие уже завершили и получили сертификаты.",
  },
  {
    number: "04",
    header: "Достигайте результат",
    text: "Получайте сертификаты, копите баллы, обменивайте их на призы и переходите на более высокий уровень.",
  },
];

type input = {
  mail?: string;
  name?: string;
  lastname?: string;
  password?: string;
  country?: string;
  countryCode?: string;
  city?: string;
  company?: string;
  confirmPass?: string;
};

const Welcome = (p: IProps) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [isMarked, setIsMarked] = useState(false);
  const [input, setInput] = useState<input>({});
  const [passError, setPassError] = useState<boolean>(false);
  const [mailError, setMailError] = useState<boolean>(false);
  const [stage, setStage] = useState<stagesLogin | undefined>(undefined);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [registerError, setRegisterError] = useState<boolean>(false);
  const [recoverError, setRecoverError] = useState<boolean>(false);

  const scrollElementsIds = [
    "about",
    "overlay_format",
    "Prizes",
    "ForCompanyes",
  ];

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);

  const toggleMark = () => {
    setIsMarked(!isMarked);
  };

  const navigate = useNavigate();
  const handleRegister = () => {
    const data: UserRegister = {
      email: input.mail || "",
      name: input.name || "",
      lastname: input.lastname || "",
      password: input.password || "",
      // password_copy: input.confirmPass || "",
      city: input.city || "",
      company: input.company || "",
      country: input.country || "",
    };
    userRegister(data).then((r) => {
      // @ts-ignore
      if (!r.error) {
        p.onAuth(true);
        navigate(AppRoutes.Main, { replace: true });
      } else {
        setRegisterError(true);
      }
    });
  };

  const handleLogin = () => {
    const data = {
      email: input.mail || "",
      password: input.password || "",
    };
    login(data.email, data.password).then((e) => {
      // @ts-ignore
      if (!e.bad) {
        p.onAuth(true);
        navigate(AppRoutes.Main, { replace: true });
      } else {
        setLoginError(true);
      }
    });
  };
  const handleRecover = () => {
    const data = {
      email: input.mail || "",
    };
    passwordRecovery(data).then((e) => {
      // @ts-ignore
      if (e.status == "ok") {
        setStage("waited");
      } else {
        setRecoverError(true);
      }
    });
  };

  useEffect(() => {
    input.password === input.confirmPass
      ? setPassError(false)
      : setPassError(true);
  }, [input.confirmPass, input.password]);

  const layer = useRef<IParallax>(null);
  const size = useResize();
  return (
    <div className="relative">
      <WelcomeHeader
        scrollIds={scrollElementsIds}
        scrollTo={(el) => {
          layer.current?.scrollTo(el);
        }}
        setModal={setStage}
        onClick={() => {
          setMenuOpened(true);
        }}
      />

      <MainScreen />
      {!size.isScreenSm && <MainCompactNav setModal={setStage} />}

      <MainAbout />

      <MainFormats />

      <div className="w-full pt-14 -mt-10 z-[1] relative bg-white rounded-tl-[48px] rounded-tr-[48px]">
        <div className="w-full flex justify-start px-[5%] flex-col mt-14 mb-10">
          <p className="text-[72px] base sm:text-[36px]">Этапы обучения</p>
        </div>

        <div>
          <div className="w-full  flex justify-between px-[5%] flex-row bg-white  items-center sm:block">
            <div className="h-full flex items-center">
              <StageCard {...stages[0]} />
            </div>
            <div className={`sm:hidden`}>
              <img src={finger} alt="" className="bg-white " />
            </div>
          </div>
        </div>

        <div className="w-full  flex justify-between px-[5%] flex-row bg-white border-t  items-center sm:block">
          <div className="h-full flex items-center">
            <StageCard {...stages[1]} />
          </div>
          <div className={`sm:hidden`}>
            <img src={course} alt="" className="bg-white" />
          </div>
        </div>

        <div className="w-full flex justify-between px-[5%] flex-row bg-white border-t items-center sm:block">
          <div className="h-full flex items-center">
            <StageCard {...stages[2]} />
          </div>
          <div className={`sm:hidden`}>
            <img src={cabinet} alt="" className="bg-white" />
          </div>
        </div>

        <div className="w-full  flex justify-between px-[5%] flex-row bg-white border-t items-center  sm:block">
          <div className="h-full flex items-center">
            <StageCard {...stages[3]} />
          </div>
          <div className={`sm:hidden`}>
            <img src={cert} alt="" className="bg-white" />
          </div>
        </div>
      </div>

      <MainLearn />

      <MainRegister
        button={(el) => {
          setInput({ mail: el });
          setStage("register");
        }}
      />

      <MainStudy />

      <MainPrizes />

      <MainFeatures
        button={(el) => {
          setInput({ mail: el });
          setStage("register");
        }}
      />

      <div
        className={`${
          menuOpened ? "backdrop-blur-sm" : "hidden"
        } fixed h-full w-full `}
      >
        <div
          className={`w-[300px] bg-white h-full fixed right-0 rounded-l-3xl shadow-recomendation sm:w-full sm:h-[300px]`}
        >
          <div
            className={`right-10 fixed top-10`}
            onClick={() => {
              setMenuOpened(false);
            }}
          >
            <ModalCross />
          </div>
          <div
            className={`flex flex-col justify-center items-center h-full gap-8 base font-semibold`}
          >
            <div
              onClick={() => {
                layer.current?.scrollTo(1);
                setMenuOpened(false);
              }}
            >
              О программе
            </div>
            <div
              onClick={() => {
                layer.current?.scrollTo(!size.isScreenSm ? 2.6 : 2);
                setMenuOpened(false);
              }}
            >
              Форматы
            </div>
            <div
              onClick={() => {
                layer.current?.scrollTo(!size.isScreenSm ? 12 : 10);
                setMenuOpened(false);
              }}
            >
              Бонусы и награды
            </div>
            <div
              onClick={() => {
                layer.current?.scrollTo(!size.isScreenSm ? 13 : 11);
                setMenuOpened(false);
              }}
            >
              Для компаний
            </div>
          </div>
        </div>
      </div>
      {/*<MainCompactNav setModal={setStage} />*/}

      <ModalWrapper
        withoutBanner={true}
        header={``}
        text={``}
        onClose={() => {
          setStage(undefined);
          setInput({});
          setIsMarked(false);
        }}
        visible={stage !== undefined}
        mainClass={`rounded-[32px] ${
          stage === "register" && size.isScreenSm
            ? "min-w-[620px]"
            : stage === "login"
            ? "min-w-[420px]"
            : !size.isScreenSm
            ? "min-w-[420px]"
            : "min-w-[500px]"
        }`}
        crossClass={`p-[3%]`}
      >
        {stage === "login" && (
          <div className={`flex flex-col items-start pr-4 mb-8`}>
            <div className={`base text-[32px] `}>
              <p>Авторизация</p>
            </div>
            <div className={`flex flex-col w-full`}>
              <div className={`mt-10`}>
                <MainInput
                  onChange={(e) => {
                    setLoginError(false);
                    setMailError(false);
                    setInput({ ...input, mail: e });
                  }}
                  id={"mail"}
                  name={"E-mail"}
                />
                <div className={`mt-4`}>
                  <MainInput
                    onChange={(e) => {
                      setLoginError(false);
                      setInput({ ...input, password: e });
                    }}
                    id={"password"}
                    name={"Пароль"}
                  />
                  <p
                    className={`baseMicro mt-1 text-CloseDeadlineAccent ${
                      loginError ? "" : "hidden"
                    }`}
                  >
                    Неверный логин или пароль
                  </p>
                </div>
                <div className={`mt-8`}>
                  <Button
                    text={"Войти"}
                    className={`h-[62px] rounded-[32px] font-semibold text-[18px] base w-full`}
                    disabled={!input.password || !input.mail}
                    onClick={handleLogin}
                  />
                </div>
                <div className={`flex flex-row justify-center base mt-10`}>
                  <p>Забыли пароль?</p>
                  <p
                    className={`ml-3 text-MainAccent cursor-pointer font-semibold`}
                    onClick={() => {
                      setStage("recover");
                    }}
                  >
                    Восстановить
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {stage === "recover" && (
          <div>
            <div
              className={`flex-row flex items-center cursor-pointer`}
              onClick={() => {
                setStage("login");
              }}
            >
              <img src={arrow} alt="" />
              <p className={`ml-4 text-SecondaryAccent base`}>Назад ко входу</p>
            </div>
            <div className={`flex flex-col items-start pr-4 mb-8 mt-4`}>
              <div className={`base text-[32px] `}>
                <p>Восстановление пароля</p>
              </div>
              <div className={`base mt-4`}>
                <p>
                  Пожалуйста, введите адрес электронной почты, которая привязана
                  к вашему аккаунту.
                </p>
              </div>
              <div className={`w-full mt-8`}>
                <MainInput
                  onChange={(e) => {
                    setLoginError(false);
                    setMailError(false);
                    setInput({ ...input, mail: e });
                  }}
                  value={input.mail}
                  id={"mail"}
                  name={"E-mail"}
                />
                <p
                  className={`baseMicro mt-1 text-CloseDeadlineAccent ${
                    recoverError ? "" : "hidden"
                  }`}
                >
                  Пользователь с таким email не найден
                </p>
              </div>
              <div className={`w-full mt-10`}>
                <Button
                  text={"Продолжить"}
                  className={`h-[62px] rounded-[32px] font-semibold text-[18px] base w-full`}
                  disabled={!input.mail}
                  onClick={handleRecover}
                />
              </div>
            </div>
          </div>
        )}
        {stage === "waited" && (
          <div>
            <div
              className={`flex-row flex items-center cursor-pointer`}
              onClick={() => {
                setStage("login");
              }}
            >
              <img src={arrow} alt="" />
              <p className={`ml-4 text-SecondaryAccent base`}>Назад ко входу</p>
            </div>
            <div className={`flex flex-col items-start pr-4 mb-8 mt-4`}>
              <div className={`base text-[32px] `}>
                <p>Восстановление пароля</p>
              </div>
              <div className={`base mt-12`}>
                <p>
                  На вашу электронную почту была направлена ссылка для сброса
                  пароля
                </p>
              </div>
            </div>
          </div>
        )}
        {stage === "register" && (
          <div className={`flex flex-col items-start px-4 mb-16 `}>
            <div className={`base text-[32px] `}>
              <p>
                Начните учиться{" "}
                <span className={`text-MainAccent`}>прямо сейчас</span>
              </p>
            </div>
            <div className={`flex flex-col w-full]`}>
              <div className={`mt-10`}>
                <MainInput
                  value={input.mail}
                  error={mailError}
                  onChange={(e) => {
                    setRegisterError(false);
                    if (
                      e.match(
                        "(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)"
                      ) ||
                      e.length === 0
                    ) {
                      setMailError(false);
                      setInput({ ...input, mail: e });
                    } else {
                      setMailError(true);
                    }
                  }}
                  id={"mail"}
                  name={"E-mail"}
                />
              </div>
              <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
                <MainInput
                  onChange={(e) => {
                    setInput({ ...input, name: e });
                  }}
                  id={"name"}
                  name={"Имя"}
                />
                <MainInput
                  onChange={(e) => {
                    setInput({ ...input, lastname: e });
                  }}
                  id={"lastname"}
                  name={"Фамилия"}
                />
              </div>
              <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
                <MainInput
                  error={passError}
                  onChange={(e) => {
                    setInput({ ...input, password: e });
                  }}
                  id={"password"}
                  name={"Пароль"}
                />
                <MainInput
                  error={passError}
                  onChange={(e) => {
                    setInput({ ...input, confirmPass: e });
                  }}
                  id={"confirmPass"}
                  name={"Повторите пароль"}
                />
              </div>
              <div className={`flex flex-row mt-4 gap-4 sm:flex-col`}>
                <MainSelector
                  select={input.country}
                  setInput={(e, c) => {
                    setInput({ ...input, country: e, countryCode: c });
                  }}
                  items={Countries}
                  label="Страна"
                />
                <MainInput
                  onChange={(e) => {
                    setInput({ ...input, city: e });
                  }}
                  id={"city"}
                  name={"Город"}
                />
              </div>
              <div className={`mt-4`}>
                <MainInput
                  onChange={(e) => {
                    setInput({ ...input, company: e });
                  }}
                  id={"company"}
                  name={"Компания"}
                />
                <p
                  className={`baseMicro mt-1 text-CloseDeadlineAccent ${
                    registerError ? "" : "hidden"
                  }`}
                >
                  Адрес электронной почты занят
                </p>
              </div>
              <div className="flex items-start my-[30px]">
                <div
                  onClick={toggleMark}
                  className={`min-w-[15px] min-h-[15px] mr-[10px] cursor-pointer border-2 border-SecondaryAccent-one ${
                    isMarked ? "bg-MainAccent" : ""
                  }`}
                ></div>
                <p className="baseMicro text-sm">
                  Заполняя настоящую регистрационную форму, я даю согласие
                  акционерному обществу «Систэм Электрик» на обработку
                  содержащихся в ней персональных данных в соответствии с
                  условиями и порядком обработки моих персональных данных,
                  установленных{" "}
                  <Link
                    className="baseUnderline text-sm text-MainAccent"
                    to="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                  >
                    {" "}
                    Политикой акционерного общества «Систэм Электрик»{" "}
                  </Link>{" "}
                  в отношении обработки персональных данных и сведениями о
                  реализуемых требованиях к защите персональных данных, в целях
                  получения информации о продуктах и доступных решениях Систэм
                  Электрик, акцептом которых является заполнение данной формы
                </p>
              </div>
              <Button
                text={"Зарегистрироваться"}
                className={`h-[72px] rounded-[32px] font-semibold text-[18px] base`}
                disabled={
                  mailError ||
                  passError ||
                  !isMarked ||
                  !input.password ||
                  !input.mail ||
                  !input.city ||
                  !input.company ||
                  !input.lastname ||
                  !input.countryCode ||
                  !input.name
                }
                onClick={handleRegister}
              />
            </div>
          </div>
        )}
      </ModalWrapper>
    </div>
  );
};

export default Welcome;
