import {useEffect, useState} from "react";
import down from "../../assets/img/promoDown.svg";
import Button from "../../components/Button";
import { orderPrise } from "../../network/PromoApi/promoApi";
import { priseOrderReq } from "../../network/PromoApi/promoInterfaces";
import ModalWrapper from "../../components/ModalWrapper";
import { OrderPriseRes } from "../../network/universalInterfaces";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";

import zont from "../../assets/img/welcome/prizes/zont.png"
import cup from "../../assets/img/welcome/prizes/cup.png"
import bag from "../../assets/img/welcome/prizes/bag.png"

const MAIL_REGEX = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"

const PrizeOfFirstProject = ({}) => {

    const [input, setInput] = useState<priseOrderReq>({});
    const [mailError, setMailError] = useState<boolean>(false);
    const selector = document.getElementById("selector");
    const [formIsOpen, setFormIsOpen] = useState<boolean>(false)
    const [orderResponse, setOrderResponse] = useState<OrderPriseRes>()

    const [isModal, handleModal] = useState<boolean>(false)
    const [isErrorModal, toggleIsErrorModal] = useState<boolean>(false)
    const [modalChild, setModalChild] = useState<any>()
    const prises = [
        {
            id: 0,
            name: "Зонт складной Manifest со светоотражающим куполом, серый",
            img: zont
        },
        {
            id: 1,
            name: 'Беспроводное зарядное устройство "ShineMax SOFTTOUCH", 10000 mAh, черный',
            img: "https://3lapi.devtoprod.ru/event_images/2.jpg"
        },
        {
            id: 2,
            name: "Отвертка и набор бит Xiaomi Mi Precision Screwdriver Kit",
            img: "https://3lapi.devtoprod.ru/event_images/3.jpg"
        },
        {
            id: 3,
            name: "Кофер софт-тач NEO CO12s (белый)",
            img: cup
        },
        {
            id: 4,
            name: "Сумка для покупок Burst Oneworld, серая",
            img: bag
        },
    ]

    const selectItems = prises.map((pr) => {
        return <p
            className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
            onClick={(event) => {
                if (selector) {
                    selector.blur();
                }
                setInput({
                    ...input,
                    prise: event.currentTarget.textContent || "",
                });
            }}
        >
            {pr.name}
        </p>
    })
    const prizeList = prises.map((pr) => {
        return <div className={"flex flex-col w-48 h-80"}>
            <div className="flex justify-center mb-3"><img className={"h-[207px]"} src={pr.img} alt="PriseImg"/></div>
            <p className="base">{pr.name}</p>
        </div>
    })

    const tryRequestOrder = () => {
        orderPrise(input).then((r: OrderPriseRes) => {
            setOrderResponse(r)
        })
    }

    useEffect(() => {
        if(orderResponse?.status === "no_send") {
            toggleIsErrorModal(true)
            setModalChild(<div><p className={`base`}>
            Мы не нашли указанный e-mail среди участников акции <br/> Развивай SYSTEME».
            Пожалуйста, попробуйте снова или <br/> <a href="/create-systeme" className="underline text-blue-500 hover:cursor-pointer">
            зарегистрируйте проект</a>, чтобы получить подарок.
         </p></div>)
            handleModal(true)
        }
        if(orderResponse?.status === "getted") {
            setModalChild(<div><p className={`base`}>
            Вы уже оформили заявку на получение приза за регистрацию первого
              проекта. Если у вас появился вопрос <Link className="underline text-blue-500 hover:cursor-pointer" to={AppRoutes.Question}>
              напишите нам</Link>
         </p></div>)
            toggleIsErrorModal(true)
            handleModal(true)
        }
        if(orderResponse?.status === "ok") {
            setModalChild(<div><p className={`base`}>
            Мы свяжемся с Вами по электронной почте <br /> и уточним детали для вручения подарка
         </p></div>)
            toggleIsErrorModal(false)
            handleModal(true)
        }
    }, [orderResponse])

    return <div>
        {formIsOpen ? <div className="flex flex-col items-center pt-16">
                <p className={`base font-bold text-[26px] leading-[35px] mb-5`}>
                    Заказ приза за первый проект </p>
                <div className={`flex flex-col items-start w-[460px] gap-3 mb-8`}>
                    <div className="relative w-full ">
                        <input
                            type="text"
                            id="name"
                            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page"
                            placeholder=" "
                            required={true}
                            onChange={(event) => {
                                setInput({...input, name: event.currentTarget.value});
                            }}
                        />

                        <label
                            htmlFor="name"
                            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
                        >
                            Ваше имя *
                        </label>
                    </div>
                    <div className="relative w-full">
                        <input
                            type="text"
                            id="mail"
                            className={`block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page ${
                                mailError ? "border-CloseDeadlineAccent" : ""
                            } first_page`}
                            placeholder=" "
                            onChange={(event) => {
                                if (
                                    event.currentTarget.value.match(
                                        MAIL_REGEX
                                    ) ||
                                    event.currentTarget.value.length === 0
                                ) {
                                    setMailError(false);
                                   
                                } else {
                                    setMailError(true);
                                }
                                setInput({...input, email: event.currentTarget.value});
                            }}
                            
                            required={true}
                        />
                        {mailError && (
                            <p className="text-[12px] leading-4 text-CloseDeadlineAccent mt-0.5 base">
                                Пожалуйста, проверьте введенный адрес
                            </p>
                        )}

                        <label
                            htmlFor="mail"
                            className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
                        >
                            Введите E-Mail, указанный при регистрации проекта *
                        </label>
                    </div>
                    <div
                        className="flex items-center relative px-4 rounded-lg h-12 w-full group focus:border-MainAccent border border-footer text-[14px] leading-4 text-SecondaryAccent-dark "
                        tabIndex={0}
                        id="selector"
                    >
              <span id="selectedPrise">
                {!input.prise ? "Выберите приз *" : input.prise}
              </span>
                        <img
                            src={down}
                            className="absolute right-4 transition-all  group-focus:-scale-y-100  group-focus:transform"
                            alt=""
                        ></img>
                        <div
                            className="hidden z-20 group-focus:flex w-full mt-1 flex-col absolute bg-white top-full left-0 p-6 shadow-plate">
                            {selectItems}
                        </div>
                    </div>
                </div>
                <Button
                    text={`Отправить`}
                    className={`h-14 w-40 rounded-full`}
                    onClick={() => {
                        tryRequestOrder()
                    }}
                    disabled={!(input.name && input.email && input.prise)}
                />
            </div> :
            <div className={"flex flex-col items-center pt-16 sm:pt-4 sm:px-4"}>
                <p className={`w-[100%] base font-bold text-[26px] leading-[35px] mb-5`}>
                    Брендированные призы за регистрацию первого проекта </p>
                <p className={'base w-[100%] mb-14'}>Получите гарантированный приз за участие в акции «Создавай
                    SYSTEME»! <br/>
                    Каждый участник может выбрать один из предложенных ниже призов независимо от количества
                    зарегистрированных
                    в акции проектов и от количества набранных в рамках акции баллов Systeme Points.</p>
                <div className={"flex flex-wrap w-full justify-around gap-8 mb-14"}>
                    {prizeList}
                </div>
                <div className="flex sm:w-full w-96 h-14 mb-4">
                    <Button onClick={() => {
                        setFormIsOpen(true)
                    }} className="w-[100%] rounded-[30px]" text="Выбрать приз"></Button>
                </div>
            </div>}
            <ModalWrapper
                isError = {isErrorModal}
                header={isErrorModal ? "Ошибка заявки": "Заявка оформлена"}
                text={``}
                onClose={() => handleModal(false)}
                visible={isModal}
                    >
                        {modalChild}
                    </ModalWrapper>
    </div>
}

export default PrizeOfFirstProject
