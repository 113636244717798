import React, { useEffect, useRef, useState } from "react";

type IProps = {
  onChange: (e: string) => void;
  id: string;
  name: string;
  error?: boolean;
  value?: string;
  prefix?: string;
  className?:string
};
const MainInput = (p: IProps) => {
  const prefix = useRef<HTMLParagraphElement>(null)
  const [prefixVisible, setPrefixVisible] = useState<boolean>(false);



  return (
    <div className="flex items-center relative w-full ">
      <input
        type={
          p.id === "password" || p.id === "confirmPass" ? "password" : "text"
        }
        id={p.id}
        className={`block rounded-[32px] h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none focus:outline-none focus:ring-0 focus:border-MainAccent peer first_page ${
          p.error ? "border-CloseDeadlineAccent" : ""
        } ${p.className}`}
        placeholder=" "
        value={p.value}
        required={true}
        onChange={(event) => {
          p.onChange(event.currentTarget.value);
        }}
      />
      {p.prefix && (
        <p ref={prefix} className="absolute  peer-placeholder-shown:hidden peer-focus:block left-4 top-5 text-SecondaryAccent-dark text-sm ">{p.prefix}</p>
      )}
      <label
        htmlFor={p.id}
        className="absolute text-xs text-SecondaryAccent-dark base  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-SecondaryAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
      >
        {p.name}
      </label>
    </div>
  );
};

export default MainInput;
