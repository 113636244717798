import { useCallback, useEffect, useState } from "react";
import logo from "../../assets/img/systemeLogo.svg";
import chinaWall from "../../assets/img/china_wall.jpg";
import circul from "../../assets/img/circul.svg";
import monitor from "../../assets/img/monitor.png";
import travel from "../../assets/img/travel.png";

import pc from "../../assets/img/pc.svg";
import pig from "../../assets/img/pig.svg";
import box from "../../assets/img/box.svg";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";

import AppRoutes from "../../structure/routes";
import CreateHeader from "../../components/Create/CreateHeader";
import LoginModal from "../../components/Create/LoginModal";
import { PromoType } from "../../network/PromoApi/promoInterfaces";

import barter from "../../assets/img/barter.png";
import project from "../../assets/img/first_project.png";
import LiderTables from "../../components/Create/LiderTables";

export interface IProps {}

type steps = {
  step: "init" | "submit" | "over";
};

type input = {
  lastname?: string;
  name?: string;
  patronomic?: string;
  mail?: string;
  phone?: string;
  select?: string;
  organization?: string;
  project_name?: string;
  orderer?: string;
  files?: any;
};

export const OldCreate = (props: IProps) => {
  const navigate = useNavigate();

  const [openLoginModal, setopenLoginModal] = useState<boolean>(false);

  const [step, setStep] = useState<steps>({ step: "init" });

  const openModal = useCallback(() => {
    navigate(AppRoutes.PromoRegistration);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tourUsersData = [
    { num: "1", user: "SIB7486", points: 8779 },
    { num: "2", user: "FE6620", points: 6069 },
    { num: "3", user: "С8008", points: 3922 },
    { num: "4", user: "SIB3350", points: 3904 },
    { num: "5", user: "NW7963", points: 3503 },
    { num: "6", user: "С2121", points: 3154 },
    { num: "7", user: "S3514", points: 3153 },
    { num: "8", user: "V2783", points: 2960 },
    { num: "9", user: "FE6554", points: 2712 },
    { num: "10", user: "C5225", points: 2535 },
    { num: "11", user: "S2333", points: 2507 },
    { num: "12", user: "NW9248", points: 2322 },
    { num: "13", user: "C2543", points: 2230 },
    { num: "14", user: "NW7235", points: 2228 },
    { num: "15", user: "SIB1560", points: 1694 },
    { num: "16", user: "C6694", points: 1639 },
    { num: "17", user: "С5093", points: 1407 },
    { num: "18", user: "C3310", points: 1339 },
    { num: "19", user: "FE2728", points: 1334 },
    { num: "20", user: "NW6653", points: 1267 },
    { num: "21", user: "SIB4812", points: 1258 },
    { num: "22", user: "U3017", points: 1240 },
    { num: "23", user: "С4242", points: 1161 },
    { num: "24", user: "C0101", points: 1155 },
    { num: "25", user: "SIB7733", points: 1082 },
    { num: "26", user: "SIB6878", points: 1049 },
    { num: "27", user: "SIB3539", points: 1019 },
    { num: "28", user: "V3123", points: 941 },
    { num: "29", user: "SIB1003", points: 925 },
    { num: "30", user: "С9234", points: 900 },
    { num: "31", user: "C7718", points: 871 },
    { num: "32", user: "U6016", points: 845 },
    { num: "33", user: "C7456", points: 825 },
    { num: "34", user: "С2642", points: 687 },
    { num: "35", user: "S3517", points: 684 },
    { num: "36", user: "V7414", points: 677 },
    { num: "37", user: "U8623", points: 657 },
    { num: "38", user: "NW9784", points: 653 },
    { num: "39", user: "V3242", points: 653 },
    { num: "40", user: "V3487", points: 653 },
    { num: "41", user: "C2400", points: 564 },
    { num: "42", user: "NW4601", points: 470 },
    { num: "43", user: "V1997", points: 469 },
    { num: "44", user: "SIB4996", points: 427 },
    { num: "45", user: "C6432 (C1787)", points: 415 },
    { num: "46", user: "U7939", points: 410 },
    { num: "47", user: "SIB7799", points: 395 },
    { num: "48", user: "V8551", points: 394 },
    { num: "49", user: "SIB6039", points: 385 },
    { num: "50", user: "U9111", points: 370 },
    { num: "51", user: "NW7739", points: 362 },
    { num: "52", user: "SIB8380", points: 343 },
    { num: "53", user: "V3010", points: 335 },
    { num: "54", user: "SIB5769", points: 320 },
    { num: "55", user: "SIB9032", points: 282 },
    { num: "56", user: "С9119", points: 281 },
    { num: "57", user: "SIB5445", points: 276 },
    { num: "58", user: "SIB7702", points: 267 },
    { num: "59", user: "U2111", points: 264 },
    { num: "60", user: "SIB5289", points: 253 },
    { num: "61", user: "V9330", points: 240 },
    { num: "62", user: "S3516", points: 218 },
    { num: "63", user: "SIB4811", points: 197 },
    { num: "64", user: "C6757", points: 178 },
    { num: "65", user: "U6103", points: 175 },
    { num: "66", user: "SIB7792", points: 168 },
    { num: "67", user: "SIB0220", points: 167 },
    { num: "68", user: "V3355", points: 145 },
    { num: "69", user: "SIB5738", points: 136 },
    { num: "70", user: "V2526", points: 122 },
    { num: "71", user: "S2564", points: 116 },
    { num: "72", user: "V9039", points: 112 },
    { num: "73", user: "C1017", points: 105 },
    { num: "74", user: "U6011", points: 95 },
    { num: "75", user: "U1521", points: 92 },
    { num: "76", user: "C3499", points: 69 },
    { num: "77", user: "U9326", points: 69 },
    { num: "78", user: "U3153", points: 56 },
    { num: "79", user: "U7170", points: 48 },
    { num: "80", user: "V3599", points: 44 },
    { num: "81", user: "NW9101", points: 40 },
    { num: "82", user: "U4748", points: 39 },
    { num: "83", user: "U0129", points: 37 },
    { num: "84", user: "V2023", points: 37 },
    { num: "85", user: "SIB1872", points: 34 },
    { num: "86", user: "V8906", points: 27 },
    { num: "87", user: "SIB4133", points: 26 },
    { num: "88", user: "S3515", points: 22 },
    { num: "89", user: "SIB7469", points: 14 },
    { num: "90", user: "NW2954", points: 11 },
    { num: "91", user: "U3905", points: 8 },
    { num: "92", user: "U7994", points: 5 },
    { num: "93", user: "SIB7058", points: 2 },
    { num: "94", user: "V2958", points: 2 },
    { num: "95", user: "NW6609", points: 1 },
    { num: "96", user: "U5594", points: 0 },
    { num: "97", user: "V2345", points: 128 },
  ];

  const tourTableRows = tourUsersData
    .sort((a, b) => b.points - a.points)
    .map((user, i) => {
      return (
        <tr
          className={
            "border-b [&>td]:pl-10 [&>td]:py-3 [&>td]:text-[16px] [&>td]:text-[#626469] [&>td]:font-normal [&>td]:leading-6 peer-[td]:bg-red-200"
          }
          key={user.user}
        >
          <td>{i + 1}</td>
          <td>{user.user}</td>
          <td>{user.points}</td>
        </tr>
      );
    });

  return (
    <div className={`!w-full !min-w-full`}>
      {step.step === "init" && (
        <div className={`sm:bg-white bg-MainAccent !pb-0`}>
          <div className={`flex xl:flex-col flex-row justify-stretch `}>
            <div
              className={`sm:bg-MainAccent  flex flex-col justify-between items-start`}
            >
              <img
                src={logo}
                alt="logo"
                className={`sm:mt-4 sm:w-28 w-64 mt-14 mx-7`}
              />
              <div className="ml-4 mt-4 mb-4 sm:block hidden">
                <p
                  className={`sm:block hidden text-white text-4xl leading-[48px] font-semibold`}
                >
                  Развивай SYSTEME
                </p>
                <p
                  className={`sm:block hidden base text-white font-medium text-lg leading-[30px]`}
                >
                  и выигрывай от правильных решений!
                </p>
              </div>
              <img
                src={monitor}
                alt="monitor"
                className={`sm:max-w-full sm:min-w-0 min-w-[640px]`}
              />
            </div>
            <div className={`flex flex-col w-full`}>
              <div
                className={`bg-white flex flex-col items-start sm:pl-4 sm:pr-4 pl-16 pt-12 pr-36 rounded-bl-[127px] w-full`}
              >
                <div className="sm:hidden">
                  <p
                    className={`text-[50px] leading-[48px] font-semibold xl:mb-7`}
                  >
                    Развивай SYSTEME
                  </p>
                  <p
                    className={`base text-[#626469] font-medium mb-16 text-[22px] leading-[30px]`}
                  >
                    и выигрывай от правильных решений!
                  </p>
                </div>
                <p
                  className={`sm:mb-4 mb-8 font-normal sm:text-2xl sm:font-semibold text-3xl leading-[48px]`}
                >
                  Применяете в проектах оборудование Systeme Electric, Dekraft
                  или Механотроника?
                </p>
                <p className={`sm:w-full mb-8 text-[22px] leading-[30px]`}>
                Регистрируйте проекты и выигрывайте призы!
                </p>
                <Button
                  text={"Участвовать"}
                  className={`mb-11 w-full `}
                  onClick={openModal}
                />
              </div>

              <div className={`sm:ml-4 ml-16 mb-4 mt-10`}>
                <p className={`sm:text-MainAccent-dark text-[16px] leading-6`}>
                  Сроки проведения акции
                </p>
                <p
                  className={`sm:text-black text-white sm:text-base text-[22px] leading-6`}
                >
                  с 1 апреля по 31 декабря 2024 г.
                </p>
              </div>
              <div className={`sm:ml-4 ml-16`}>
                <p className={`sm:text-MainAccent-dark text-[16px] leading-6`}>
                  Подведение итогов состоится
                </p>
                <p
                  className={`sm:text-black text-white sm:text-base text-[22px] leading-6 mb-10`}
                >
                  с 1 января по 15 февраля 2025 г.
                </p>
              </div>
            </div>
          </div>

          <div
            className={`rounded-tr-[127px] flex flex-col items-start bg-white sm:px-4 px-[8%] sm:pt-16 pt-20`}
          >
            <p className={`font-semibold text-[36px] leading-[48px`}>
              Как принять участие
            </p>
            <div className={`flex w-full justify-around mt-20`}>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={circul} alt="circul" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Проектируйте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  с применением оборудования Systeme Electric, Dekraft и
                  Механотроника
                </p>
              </div>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={pc} alt="pc" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Загружайте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  документацию по проекту
                  <p
                    className={`underline text-MainAccent cursor-pointer`}
                    onClick={openModal}
                  >
                    в форме регистрации
                  </p>
                </p>
              </div>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={pig} alt="pig" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Получайте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  баллы SystemePoints за примененное в проекте оборудование
                </p>
              </div>
              <div className={`flex flex-col items-center w-64 gap-5 `}>
                <img src={box} alt="box" className={`w-20 h-20`} />
                <p className={`font-semibold text-[16px] leading-6`}>
                  Обменивайте
                </p>
                <p className={`text-[16px] leading-6 text-center`}>
                  накопленные баллы на ценные призы
                </p>
              </div>
            </div>
            <a
              href="https://workspace.systeme.ru/systeme/s/9ZsF4iY32tzdJcy"
              className={`mt-24 mx-auto`}
            >
              <Button
                text={"Правила акции"}
                className={`sm:w-full border border-MainAccent bg-white text-MainAccent hover:text-white h-12 flex items-center rounded-3xl`}
              />
            </a>
            <p className={`font-semibold text-[36px] leading-[48px] mt-20`}>
              Как увеличить шансы на победу?
            </p>
            <div className={`flex flex-col relative gap-8 mt-16`}>
              <span className={`line absolute left-10 top-10 h-5/6`}></span>
              <div className={`flex items-center  gap-6`}>
                <span className={`circle`}>1</span>
                <p
                  className={`text-[17px] text-[#626469] font-normal leading-6`}
                >
                  загружайте как можно больше проектов
                </p>
              </div>
              <div className={`flex items-center  gap-6`}>
                <span className={`circle`}>2</span>
                <p
                  className={`text-[17px] text-[#626469] font-normal leading-6`}
                >
                  применяйте в проекте максимальное количество оборудования
                  Systeme Electric, Dekraft и Механотроника
                </p>
              </div>
              <div className={`flex items-center  gap-6`}>
                <span className={`circle`}>3</span>
                <p
                  className={`text-[17px] text-[#626469] font-normal leading-6`}
                >
                  проектируйте комплексно: удваивайте баллы за применение
                  различных типов оборудования
                </p>
              </div>
            </div>
            <Button
              text={"Участвовать"}
              className={`sm:w-full mx-auto mt-16 mb-20`}
              onClick={openModal}
            />
            <p className={`font-semibold text-[36px]  leading-[48px]`}>
              Призы за баллы
            </p>
            <div className={`flex justify-around w-full mt-14`}>
              <div className={`flex items-center flex-col w-80 group`}>
                <img src={travel} alt="travel" className={`w-[90%]`} />
                <p
                  className={`text-[16px] text-center leading-6 mt-4 text-SecondaryAccent-dark`}
                >
                  Путешествие для победителей
                </p>
              </div>

              <div className={`flex flex-col w-80 group`}>
                <Link to={AppRoutes.PrizesForSystemepoints}>
                  <img src={barter} alt="barter" className={`w-[90%]`} />
                  <p
                    className={`text-[16px] text-center leading-6 mt-4 text-SecondaryAccent-dark`}
                  >
                    Приз в обмен на полученные баллы
                  </p>
                </Link>
              </div>
              <div className={`flex flex-col w-80 group`}>
                <Link to={AppRoutes.PrizeOfFirstProject}>
                  <img src={project} alt="project" className={`w-[90%]`} />
                  <p
                    className={`text-[16px] text-center leading-6 mt-4 text-SecondaryAccent-dark`}
                  >
                    Приз за первый проект
                  </p>
                </Link>
              </div>
            </div>

            <div className={"sm:w-full flex flex-col w-[100%]  mt-16 mb-20"}>
              <p className={`font-semibold text-[36px] leading-[48px] mb-16`}>
                Турнирная таблица
              </p>
              <table className={"w-[100%]"}>
                <thead
                  className={
                    "border-b text-[17px] text-[#626469] font-normal leading-6"
                  }
                >
                  <td>
                    <p className="pl-10">№ п/п</p>
                  </td>
                  <td>Номер участника</td>
                  <td>Баллы SystemePoints</td>
                </thead>
                <tbody>{tourTableRows}</tbody>
              </table>
              <Button
                text={"Участвовать"}
                className={`mx-auto  mb-20 mt-5`}
                onClick={openModal}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
