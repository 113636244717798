export enum PromoType {
  ONE = "one",
  TWO = "two",
  THREE = "three",
}

export enum PromoSpecialization {
  DILLER = "diller",
  OEM = "oem",
  SHIELD = "shield",
  CMO = "cmo",
}

export enum UserPromoRole {
  ADMIN = "admin",
  USER = "user",
}

export enum PromoErrorCode {
  InvalidRequest = 400,
  Unauthorized = 401,
  Decline = 402,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
}

export type PromoResponse<R> =
  | {
      status: "ok";
      data: R;
    }
  | {
      status: "error";
      message?: string;
      code: number;
    };

export type promoProjectReq = {
  lastname?: string;
  name?: string;
  patronomic?: string;
  mail?: string;
  phone?: string;
  select?: string;
  organization?: string;
  project_name?: string;
  orderer?: string;
  files?: any;
};

export type priseOrderReq = {
  email?: string | undefined;
  name?: string | undefined;
  prise?: string | undefined;
  message?: string | undefined;
};

export type shopOrderReq = {
  name: string;
  userId: string;
  phone: string;
  delivery: string;
  product: string;
  price: number;
};

export type promoAuthReq = {
  login: string;
  password: string;
};

export type promoRegisterReq = {
  name: string;
  lastname: string;
  patronomyc: string;
  login: string;
  password: string;
  INN: string;
  phone: string;
  company: string;
  state: string;
};

export type PromoRegisterRsp = {
  id: string;
};

export interface PromoUser {
  id: string;
  name: string;
  lastname: string;
  patronomyc: string;
  login: string;
  password: string;
  INN: string;
  phone: string;
  company: string;
  state: string;
  type: PromoType;
  role: string;
  specializtion: PromoSpecialization;
}

export type ImportError = {
  row: number;
  error: string;
};
export interface PromoInfoResp extends PromoUser {
  statistic?: {
    countA: number;
    countB: number;
    countC: number;
  };
}

export type PromoLider = {
  count: number;
  id: string;
};
export type PromoLidersResp = {
  base: {
    diler: PromoLider[];
    shield: PromoLider[];
    oem: PromoLider[];
  };
  height: {
    diler: PromoLider[];
    shield: PromoLider[];
    oem: PromoLider[];
  };
};

export type PromoStatusResp = {
  status: boolean;
};


export type PromoStatusReq = {
  status: boolean;
};

export type PromoUserResp = {
  id: string;
  firstName: string;
  lastName: string;
  patronomyc: string;
  company: string;
  state: string;
  phone: string;
  email: string;
};

export type ConfirmUserreq = {
  ids: string[];
  status: boolean;
};
