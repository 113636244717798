import { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import ModalWrapper from "../../components/ModalWrapper";
import usePrompContext from "../../components/PromoComtextProvider";
import attach from "../../assets/img/attach.svg";
import trash from "../../assets/img/trash.svg";
import file from "../../assets/img/file.svg";
import warning from "../../assets/img/warning.svg";
import { baseURL } from "../../config";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import DragDropFileContainer from "../../components/DragDropFileContainer";
import Loader from "../../components/Loader";

interface IProps {}

const PromoRegistrationFinal = (props: IProps) => {
  const navigate = useNavigate();
  const { input, setInput } = usePrompContext();

  const [rules, setRules] = useState<boolean>(false);
  const [eula, setEula] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [uniq, setUniq] = useState<string>("0");
  const [filesUpload, setFilesUpload] = useState<Array<File>>([]);

  const files = useRef<any>(null);

  const formData = new FormData();

  const onGetFiles = useCallback((files: FileList) => {
    setFilesUpload(Array.from(files));
  }, []);

  useEffect(() => {
    if (
      !(
        input.select &&
        input.lastname &&
        input.name &&
        input.mail &&
        input.phone
      )
    ) {
      navigate(AppRoutes.PromoRegistration);
    }
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    var index = 0;
    var newFiles: any = [];
    for (const f of filesUpload) {
      formData.append(index.toString(), f);
      newFiles.push({
        name: f.name,
        id: index.toString(),
      });
      index++;
    }
    formData.append("json", JSON.stringify({ ...input, files: newFiles }));
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("POST", `${baseURL}/set/promo/create_project`);
    xhr.onload = () => {
      if (xhr.status === 200) {
        setIsLoaded(false);
        setUniq(JSON.parse(xhr.responseText).ok);
        handleModal();
      }
    };
    setIsLoaded(true);
    xhr.send(formData);
    setInput({});
  };

  const handleModal = () => {
    setIsModal(!isModal);
    if (isModal) navigate(AppRoutes.Create);
  };
  console.log("FILES", input.files?.length);

  useEffect(() => {
    setInput({ ...input, files: filesUpload });
  }, [filesUpload]);

  return (
    <DragDropFileContainer onChange={onGetFiles}>
      <div
        className={`bg-MainAccent sm:rounded-bl-[30px] rounded-bl-[127px] !w-full sm:pl-4 pl-32 pt-12 pb-16 overflow-auto`}
      >
        <p
          className={`text-white font-semibold sm:text-[20px] sm:leading-[25px] text-[36px] leading-[48px]`}
        >
          Зарегистрировать проект для участия
        </p>
        <p
          className={`text-white font-semibold sm:text-[20px] sm:leading-[25px] text-[36px] leading-[48px]`}
        >
          в акции Развивай SYSTEME
        </p>
      </div>
      <div className={`flex gap-5 sm:ml-4 ml-24 mt-4`}>
        <p className={`leading-6 text-[22px] text-MainAccent`}>Шаг</p>
        <span
          className={`circle relative bottom-[54px] !bg-MainAccent border border-white`}
        >
          2
        </span>
        <p className={`sm:hidden leading-6 text-[22px] text-MainAccent`}>
          Заполните данные участника
        </p>
      </div>
      <div
        className={`sm:relative sm:bottom-[40px] flex flex-col items-start sm:ml-4 ml-24 sm:w-auto sm:pr-4 w-[840px] gap-3`}
      >
        <p className={` sm:block hidden leading-6 text-[22px] text-MainAccent`}>
          Заполните данные участника
        </p>
        <div
          className={`bg-SecondaryAccent-veryLight rounded-2xl w-full relative sm:pt-4 sm:px-6 sm:mt-6 mb-5`}
        >
          <img
            src={warning}
            alt="warning"
            className={`absolute bottom-full ml-5 translate-y-1/2`}
          />
          <p
            className={`sm:text-left text-center py-4 text-[14px] leading-4 base`}
          >
            Если хотите зарегистрировать несколько проектов, их нужно оформлять
            отдельными заявками
          </p>
        </div>
        <div className="relative w-full ">
          <input
            type="text"
            id="organization"
            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer second_page"
            placeholder=" "
            value={input.organization}
            onChange={(event) => {
              setInput({
                ...input,
                organization: event.currentTarget.value,
              });
            }}
          />

          <label
            htmlFor="organization"
            className="absolute text-xs text-SecondaryAccent  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-MainAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            Наименование проектной организации *
          </label>
        </div>
        <div className="relative w-full ">
          <input
            type="text"
            id="project_name"
            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer second_page"
            placeholder=" "
            value={input.project_name}
            onChange={(event) => {
              setInput({
                ...input,
                project_name: event.currentTarget.value,
              });
            }}
          />

          <label
            htmlFor="project_name"
            className="absolute text-xs text-SecondaryAccent  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-MainAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            Наименование проекта *
          </label>
        </div>
        <div className="relative w-full ">
          <input
            type="text"
            id="orderer"
            className="block rounded-lg h-12 px-4 py-0 pt-3 w-full text-sm text-gray-900 border border-footer appearance-none   focus:outline-none focus:ring-0 focus:border-MainAccent peer second_page"
            placeholder=" "
            value={input.orderer}
            onChange={(event) => {
              setInput({ ...input, orderer: event.currentTarget.value });
            }}
          />

          <label
            htmlFor="orderer"
            className="absolute text-xs text-SecondaryAccent  duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-4 peer-focus:text-MainAccent peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3"
          >
            Конечный заказчик *
          </label>
        </div>
        <div className="mt-8">
          <p className="base text-[22px] leading-6 mb-4">
            Загрузите документацию по проекту *
          </p>
          <p className="base text-[16px] leading-6 text-SecondaryAccent-dark">
            Принимается любая документация по проекту, содержащая данные о
            примененном в проекте оборудовании: схемы, спецификации,
            пояснительные записки, опросные листы. Приложенная документация
            должна иметь штамп с указанной датой выпуска в течение 2024 года.
            Участнику необходимо приложить два или более файла в формате: .pdf,
            .doc, .docx, .xls, .xlsx, .dwg, .rvt, .7z, .rar, .zip, .zipx
          </p>
        </div>
        <div className="w-full mt-16">
          <p className="base text-[22px] leading-6 mb-4">
            Загруженные документы
          </p>
          {filesUpload && (
            <div className="w-full" id="filesContainer">
              {Array.from(filesUpload).map((el) => {
                return (
                  <div className={`flex justify-between py-4 border-b `}>
                    <div className={`flex items-center`}>
                      <img src={file} alt="file" className={`mr-5`} />
                      <p
                        className={`font-semibold base text-sm leading-4 text-SecondaryAccent`}
                      >
                        <span className={`text-SecondaryAccent-dark`}>
                          {el.name}
                          {" / "}
                        </span>
                        {Math.floor(el.size / 1024)} КБ
                      </p>
                    </div>
                    <img
                      src={trash}
                      alt="trash"
                      className={`cursor-pointer`}
                      onClick={() => {
                        const files = filesUpload;
                        const newFiles = files?.filter(function (f: any) {
                          return f !== el;
                        });
                        setFilesUpload(newFiles);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className="flex w-full gap-3 cursor-pointer mt-2"
          id="fileBtn"
          onClick={(event) => {
            files.current.click();
          }}
        >
          <img src={attach} alt="attach"></img>
          <p className="text-sm font-semibold text-MainAccent base">
            Прикрепить файлы
          </p>
          <input
            onChange={(event) => {
              if (event.currentTarget.files) {
                onGetFiles(event.currentTarget.files);
              }
            }}
            type="file"
            ref={files}
            id="fileInput"
            name="project3"
            multiple
            accept=".pdf, .doc, .docx, .xls, .xlsx, .dwg, .rvt, .7z, .rar, .zip, .zipx"
            className="hidden"
          ></input>
        </div>
        <div className="flex gap-2 mt-16">
          <input
            type="checkbox"
            className="check"
            onChange={() => {
              setRules(!rules);
            }}
          ></input>
          <p className="base text-[12px] leading-4 text-SecondaryAccent-dark">
            Подтверждаю, что ознакомлен и согласен с{" "}
            <a
              href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
              className="text-systeme underline"
            >
              Правилами акции
            </a>
          </p>
        </div>
        <div className="flex gap-2 items-start">
          <input
            type="checkbox"
            className="check"
            onChange={() => {
              setEula(!eula);
            }}
          ></input>
          <p className="base text-[12px] leading-4 text-SecondaryAccent-dark">
            Заполняя настоящую регистрационную форму, я даю согласие
            акционерному обществу «Систэм Электрик» на обработку содержащихся в
            ней персональных данных в соответствии с условиями и порядком
            обработки моих персональных данных, установленных{" "}
            <a
              href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
              className="text-systeme underline"
            >
              Политикой акционерного общества «Систэм Электрик»
            </a>{" "}
            в отношении обработки персональных данных и сведениями о реализуемых
            требованиях к защите персональных данных, в целях получения
            информации о продуктах и доступных решениях Систэм Электрик,
            акцептом которых является заполнение данной формы
          </p>
        </div>
        <Button
          text={`Отправить`}
          className={`mt-8 sm:w-full mb-5 h-14 w-40 rounded-full`}
          onClick={handleSubmit}
          disabled={
            !(
              input.project_name &&
              input.orderer &&
              input.organization &&
              rules &&
              eula &&
              filesUpload?.length > 0
            )
          }
        />
      </div>
      <ModalWrapper
        header={"Заявка отправлена"}
        text={``}
        onClose={handleModal}
        visible={isModal}
      >
        <p className={`base`}>
          Уникальный номер участника, который будет использоваться для
          отображения вашего результата в турнирной таблице:{" "}
          <strong>{uniq}</strong>
        </p>
      </ModalWrapper>
      {isLoaded && (
        <div className="flex justify-center items-center fixed w-[100vw] h-[100vh] left-0 top-0 bg-[rgba(0,0,0,0.5)] z-50">
          <Loader />
        </div>
      )}
    </DragDropFileContainer>
  );
};

export default PromoRegistrationFinal;
