import { useState } from "react";
import Button from "../../components/Button";
import Tabbar from "../../components/Create/Tabbar";
import StatisticTab from "./StatisticTab";
import UsersTab from "./UsersTab";
import { PromoUser } from "../../network/PromoApi/promoInterfaces";
import { useAuth } from "../../Providers/PromoUserProvider";
import { logout } from "../../network/PromoApi/promoApi";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../structure/routes";

interface IProps {}

const TABS = ["Статистика", "Пользователи","Заявки"];

const PromoAdmn = (props: IProps) => {
  const navigate = useNavigate()
  const [info,setInfo] = useAuth()

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [users, setUsers] = useState<PromoUser[]>([]);
  const [inactiveUsers, setInactiveUsers] = useState<PromoUser[]>([]);


  return (
    <div className="flex flex-col min-h-[100vh] px-10">
      <div
        className={`min-h-[92px] flex flex-row items-center base justify-between`}
      >
        <p>Среда администрирования</p>
        <div>
          <Button
            text={"Выход"}
            className={
              "bg-SecondaryAccent-veryLight text-MainAccent mr-4 h-[60px] w-[124px] rounded-full hover:bg-SecondaryAccent-light font-medium sm:hidden"
            }
            onClick={() => {
              logout()
              setInfo(undefined)
              navigate(AppRoutes.Create)
            }}
          />
        </div>
      </div>
      <Tabbar titles={TABS} onSelect={setTabIndex} />
      <StatisticTab selectedTabIndex={tabIndex} tabIndex={0} />
      <UsersTab  selectedTabIndex={tabIndex} tabIndex={1} isActive users={users} setUsers={setUsers}/>
      <UsersTab  selectedTabIndex={tabIndex} tabIndex={2} users={inactiveUsers} setUsers={setInactiveUsers}/>
    </div>
  );
};

export default PromoAdmn;
