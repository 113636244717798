/**
 * Проверяет, является ли строка корректным ИНН российской компании.
 * @param inn - строка для проверки
 * @returns true, если строка соответствует формату ИНН, иначе false
 */
export function isValidINN(inn?: string): boolean {
  // ИНН должен быть строкой из 10 цифр
  if (!inn) return false;
  const innRegex = /^\d{10}$/;
  return innRegex.test(inn);
}

/**
 * Проверяет, является ли строка корректным российским номером телефона.
 * @param phoneNumber - строка для проверки
 * @returns true, если строка соответствует формату российского номера телефона, иначе false
 */
export function isValidNumber(phoneNumber?: string): boolean {
  // Российский номер телефона может начинаться с +7 или 8 и содержать 10 цифр после кода страны или оператора
  if (!phoneNumber) return false;
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phoneNumber);
}

export function isValidEmail(email?: string): boolean {
  if (!email) return false;
  const emailRegex = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)/;
  return emailRegex.test(email);
}

export function checkKeys<T>(object: T, keys?: (keyof T)[]) {
  const keysToCheck = keys || Object.keys((object as object));
  return (keysToCheck as string[]).every((k) => Boolean(object[k as keyof T]));
};
